import * as React from 'react';

interface SvgProps {
  className: string;
}

const PoinzLogo: React.FC<SvgProps> = ({ className }: SvgProps) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100.2 34"
      xmlSpace="preserve"
      fill="currentColor"
      className={className}
    >
      <g>
        <path
          d="M0.6,34c-0.2,0-0.4-0.1-0.5-0.2C0,33.6,0,33.4,0,33.3l3.8-18.6C4.3,12,4.9,7.5,5,6.7c0-0.3,0.3-0.6,0.6-0.6h6.1
		c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5l-0.1,1c1.5-1.2,3.3-1.9,5.3-1.9c4.2,0,7.1,3.3,7.1,8.2c0,6.6-4.2,13.6-12,13.6
		c-1.1,0-2.2-0.2-3-0.5l-1.4,6.4C8.1,33.8,7.8,34,7.5,34H0.6z M10.8,20.8c0.4,0.3,0.9,0.5,1.5,0.5c2.1,0,3.9-3.6,3.9-6.6
		c0-0.8-0.2-2-1.4-2c-1.5,0-2.8,1.8-3.4,4.8L10.8,20.8z"
        />
        <path
          d="M34,27.6c-5.6,0-8.2-3.5-8.2-8.8c0-7.5,4.3-13,11.7-13c5.5,0,9.3,3.6,9.3,8.7C46.8,22.3,41.5,27.7,34,27.6L34,27.6L34,27.6
		z M36.6,12.3c-2.4,0-3.6,4.1-3.6,6.5c0,0.9,0.2,2.5,1.9,2.5c2.3,0,3.5-3.9,3.5-6.6C38.4,13.6,38.1,12.3,36.6,12.3z"
        />
        <path
          d="M57.7,9.4l-3.3,17.3c-0.1,0.3-0.3,0.5-0.6,0.5H47c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.1-0.5l3.5-18.4
		c1.1,1.3,2.8,2.1,4.7,2.1C55.7,10.2,56.8,9.9,57.7,9.4z"
        />
        <path
          d="M73.4,5.9c-2.3,0-4.3,0.8-6,2.3L67.5,7c0-0.2,0-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2h-6c-0.3,0-0.6,0.2-0.6,0.5l-0.1,0.8
		c-0.3,1.7-0.5,3.7-1,5.9l-2.5,12.9c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.3,0.2,0.5,0.2h6.8c0.3,0,0.6-0.2,0.6-0.5l1.6-8.4
		c0.8-3.9,2.3-5.7,3.5-5.7c0.4,0,0.8,0,0.8,1.3c0,0.6-0.1,1.3-0.2,1.9l-2,10.7c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.3,0.2,0.5,0.2H76
		c0.3,0,0.6-0.2,0.6-0.5l2.2-11.4c0.2-0.9,0.3-2.5,0.3-3.5C79.1,8.1,77,5.9,73.4,5.9z"
        />
        <path
          d="M79.3,27.2c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.1-0.5l0.7-4.1c0-0.1,0.1-0.2,0.2-0.3l5.1-5.3
		c1.4-1.4,2.5-2.5,3.9-3.7h-6.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.1-0.5L83,6.8c0.1-0.3,0.3-0.5,0.6-0.5h15.9
		c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.1,0.5l-0.8,4.3c0,0.1-0.1,0.2-0.2,0.3l-4.9,5.1c-1.4,1.4-2.5,2.6-3.9,3.7h6.9
		c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.1,0.5l-1.1,5.5c-0.1,0.3-0.3,0.5-0.6,0.5L79.3,27.2L79.3,27.2z"
        />
        <path d="M54.5,0c-2.2,0-4.1,1.8-4.1,4s1.8,4,4.1,4c2.2,0,4.1-1.8,4.1-4C58.6,1.8,56.8,0,54.5,0z" />
      </g>
    </svg>
  );
};

PoinzLogo.displayName = 'PoinzLogo';

export default PoinzLogo;
